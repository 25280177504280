import { aqiCalculator } from "./aqiCalculator";

export async function getData(reqOptions, api_url){

    const resultObj = {};
    const response = await fetch(api_url, reqOptions);
    if(!response.ok){
        console.log(`HTTP error! Status: ${response.status}`);
        return null;
    }
    const jsonify = await response.json();
    const data = jsonify.data;

    if(data[0] === undefined){

        console.log("No data received condition");
        // const prev_data = await getLastData(reqOptionsRaw, thing_id);
        //     if(jsonData.param === undefined){
        //         aqi = updateData(prev_data);
        //     }
        //     else if (prev_data !== -1 && prev_data.parameter_values['pm2.5'] !== undefined) {
        //         aqi = prev_data.parameter_values['pm2.5']
        //     }
        //     else{
        //         aqi = "NA";
        //     }
        //     if(prev_data === -1 || typeof(prev_data) === undefined){
        //         x = aqi
        //         y = "Device Offline. Check network and power Connectivity"
        //     }
        //     else{
        //         x = prev_data.parameter_values;
        //         y = prev_data.time;
        // }

    }
    else{
        const temp = data.pop();                // Here temp contains only: time, thing_id, parameter_values(obj)
        if(temp.time){
            resultObj.time = temp.time;
        }
        else{
            resultObj.time = "Device Offline. Check connection";
        }
        // aqiCalculator();
    }

    console.log("TESTING", resultObj);

}