import React from 'react';
import './smartcity.css';

function Header() {
    return (
        <div className='smart-header'>
            <img src='/img/smartcity_bbsr_logo.png' alt='SmartCity Logo' />
            <img src='/img/Aurassure_smart_logo.png' alt='Aurassure Logo'/>
            {/* <img src='/img/google.png' alt='Google Logo' /> */}
        </div>
    )
}

export default Header