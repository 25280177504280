import React from 'react';
import { useState, useEffect } from 'react';

function MajorBody({data}) {

    const [imageSrc, setImageSrc] = useState('/img/default-aqi-image.png');

    useEffect(() => {
        // Check the status of the first data item and change the image accordingly
        if (data && data[0] && data[0].locationStatus) {
            switch (data[0].locationStatus) {
                case 'Good':
                    setImageSrc('/img/good-aqi-image.png');
                    break;
                case 'Satisfactory':
                    setImageSrc('/img/satisfactory-aqi-image.png');
                    break;
                case 'Moderately Polluted':
                    setImageSrc('/img/moderate-aqi-image.png');
                    break;
                case 'Very Poor':
                    setImageSrc('/img/verypoor-aqi-image.png');
                    break;
                case 'Poor':
                    setImageSrc('/img/poor-aqi-image.png');
                    break;
                case 'Severe':
                    setImageSrc('/img/severe-aqi-image.png');
                    break;
                default:
                    setImageSrc('/img/default-aqi-image.png');
            }
        }
    }, [data]);
    
    const status = (data !== undefined && data[0] !== undefined && data[0].locationStatus !== undefined) ? (data[0].locationStatus): "";
    let color;
    switch (status) {
        case 'Good':
            color = '#00b050';
            break;

        case 'Satisfactory':
            color = '#92d050';
            break;

        case 'Moderately Polluted':
            color = '#E9D30E';
            break;

        case 'Poor':
            color = '#ed7d31';
            break;

        case 'Very Poor': 
            color = '#ff0000';
            break;

        case 'Severe':
            color = '#c00000';
            break;

        default:
            color = "#C9c9c5";
            break;
    }

    return (
        <div className='smart-major'>
            <div className='major-location-container'>
                <section>
                    {/* {(data[0] !== undefined) && (data[0].last_data_received_time !== undefined) && <p>{data[0].last_data_received_time}</p>} */}
                    {(data[0] !== undefined) && (data[0].last_data_received_time !== undefined) && (
                        <p>
                            {/* {data[0].last_data_received_time} |  */}
                            {new Date().toLocaleString('en-GB', {
                                day: '2-digit',
                                month: '2-digit',
                                year: 'numeric',
                                hour: '2-digit',
                                minute: '2-digit',
                                second: '2-digit',
                                hour12: false,
                            })}
                        </p>
                    )}
                    {(data[0] !== undefined) && (data[0].location) && <p>{data[0].location}</p>}
                </section>
                <div className='smart-temp-humid-container'>
                    {data.map((item, itemIndex) => (
                        (item.name === 'Temperature' || item.name === 'Humidity') && (
                            <React.Fragment key={itemIndex}>
                                {item.name === 'Temperature' && (
                                    <section className='smart-temperature-container'>
                                        <img src='/img/smart_temperature.png' alt='Temperature Icon' className='temperature-icon'/>
                                        <p className='temp-text'>{Math.round(Number(item.value))} {item.unit}</p> 
                                    </section>
                                )}
                                {item.name === 'Humidity' && (
                                    <section className='smart-humidity-container'>
                                        <img src='/img/smart_humid.png' alt='Humid Icon' className='humid-icon'/>
                                        <p className='humid-text'>{Math.round(Number(item.value))} {item.unit}</p>
                                    </section>
                                )}
                            </React.Fragment>
                        )
                    ))}
                </div>
            </div>
            <div className='smart-aqi'>
                <div className='aqi-value-container' style={{border: `2px solid ${color}`}}>
                    <p className='aqi-text'>
                        AQI
                    </p>
                    <section>
                        {(data[0] !== undefined) && (
                            <p className='aqi-value'>{data[0].aqi}</p>
                        )}
                    </section>
                </div>
                {/* <p className='aqi-status'>
                    {(data !== undefined) && (data[0] !== undefined) && data[0].locationStatus}
                </p> */}
                <p className='aqi-status'>
                    {(data !== undefined && data[0] !== undefined) 
                        ? (data[0].locationStatus === 'Moderately Polluted' 
                            ? 'Moderate' 
                            : data[0].locationStatus) 
                        : ''}
                </p>
            </div>
            <div className='aqi-status-image-container'>
                <img src={imageSrc} alt={data[0]?.status || 'AQI Condition'} className='aqi-condition-image'/>
            </div>
        </div>
    )
}

export default MajorBody