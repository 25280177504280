export async function getSmartCityData(thing_id, jsonData) {
    const myHeaders = new Headers();
    myHeaders.append("Auth-Token", "JGt4k44fuxg3f6MDfhk6Jl7kkfpFnVN0QPcx8bL74DE3pptzyL2SZa6zGK96xR01");

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
    };

    const param_config = jsonData.param_config;

    try {
        const response = await fetch(
            `https://ems.smartcitybhubaneswar.gov.in/api/cities/bhubaneswar/data/devices/${thing_id}`,
            requestOptions
        );

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        if(result.Status === 'Success'){            
            const last_data_received_time = result.Timestamp;
            const location_name = result.LocationName;
            const aqi = result.AQI;
            const paramValues = result.ParameterValues;
            const locationStatus = result.LocationStatus;
            const data = [];
            paramValues.map((item, index) => {
                if (param_config.includes(item.Name)) {
                    const [val, unit] = item.Value.split(" ");
    
                    // Store the data in an object.
                    const tempObj = {
                        aqi: aqi,
                        last_data_received_time: last_data_received_time,
                        location: location_name,
                        name: item.Name,
                        value: val,
                        unit: unit,
                        status: item.Status,
                        locationStatus: locationStatus
                    }
                    data.push(tempObj);
                }
            })
            return data;
        }
        else{
            return "Some error occured";
        }
        
    } catch (error) {
        console.error("Error fetching data:", error);
    }
}
