import 'bootstrap/dist/css/bootstrap.min.css'; 
import 'bootstrap/dist/js/bootstrap.bundle.min';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import jsonData0 from './Data_file/aes_11878.json';
import jsonData1 from './Data_file/aes_11879.json';
import jsonData2 from './Data_file/aes_11880.json';
import jsonData3 from './Data_file/aes_11881.json';
import jsonData4 from './Data_file/airth.json';
import jsonData5 from './Data_file/ayodhya.json';
import jsonData6 from './Data_file/cars24.json';
import jsonData7 from './Data_file/lti_mindtree.json';
import jsonData8 from './Data_file/tataariana.json';
import jsonData9 from './Data_file/ubreathe.json';
import jsonData10 from './Data_file/ultrapure.json';
import jsonData11 from './Data_file/ultrapure1.json';
import jsonData12 from './Data_file/template.json';
import jsonData13 from './Data_file/demo_omniion.json';
import jsonData14 from './Data_file/demo.json';
import jsonData15 from './Data_file/demo_9RN64GPE.json';
import jsonData16 from './Data_file/driiv.json';
import jsonData17 from './Data_file/driiv1.json';
import jsonData18 from './Data_file/ubreathe_new.json';
import jsonData19 from './Data_file/aes_13277.json';
import jsonData20 from './Data_file/demo1.json';
import jsonData21 from './Data_file/ubreathe1.json';
import jsonData22 from './Data_file/aes_11875.json';
import jsonData23 from './Data_file/ucan_centurion.json';
import jsonData24 from './Data_file/tdav_LBAZHT0G.json';
import jsonData25 from './Data_file/tdav_ZJRXKBYL.json';
import jsonData26 from './Data_file/tdav_R90J081M.json';
import jsonData27 from './Data_file/tdav_9RWJOVC5.json';
import jsonData28 from './Data_file/tdav_4X8I08SG.json';
import jsonData29 from './Data_file/tdav_Y060A0NP.json';
import jsonData30 from './Data_file/tdav_8IM301X6.json';
import jsonData31 from './Data_file/tdav_GEXFPYY4.json';
import jsonData32 from './Data_file/tdav_QPISM9E8.json';
import jsonData33 from './Data_file/demo_testing.json';
import jsonData34 from './Data_file/aes_14524.json';
import jsonData35 from './Data_file/aes_shoba_housing.json';
import jsonData36 from './Data_file/aes_four_devices.json';
import jsonData37 from './Data_file/ace_tech_129.json';
import jsonData38 from './Data_file/ace_tech_150.json';
import jsonData39 from './Data_file/ace_tech_153.json';
import jsonData40 from './Data_file/ace_tech.json';
import jsonData41 from './Data_file/ace_tech_la_buildtech.json';
import jsonData42 from './Data_file/ace_tech_star_craft.json';
import jsonData43 from './Data_file/ubreathe_dc_mansion.json';
import jsonData44 from './Data_file/oxy_infra.json';
import jsonData45 from './Data_file/vishwa_marketing.json';
import jsonData46 from './Data_file/aes_afnkro80.json';
import jsonData47 from './Data_file/iclei_coimbatore.json';
import jsonData48 from './Data_file/ace_tech_sector_22D.json';
import jsonData49 from './Data_file/charms_india.json';
import jsonData50 from './Data_file/rudra_constructions.json';
import jsonData51 from './Data_file/okhae_electronics.json';
import jsonData52 from './Data_file/countryside_properties.json';
import jsonData53 from './Data_file/aes_16750.json';
import jsonData54 from './Data_file/bikaram_industries.json';
import jsonData55 from './Data_file/fit_bit.json';
import jsonData56 from './Data_file/aes_16968.json';
import jsonData57 from './Data_file/aes_16969.json';
import jsonData58 from './Data_file/emaar_india_ibc61.json';
import jsonData59 from './Data_file/ultrapure_soma_roadis.json';
import jsonData60 from './Data_file/rpdpl_17015.json';
import jsonData61 from './Data_file/rpdpl_17016.json';
import jsonData62 from './Data_file/rs_growplast.json';
import jsonData63 from './Data_file/albin_industries.json';
import jsonData64 from './Data_file/ace_tech_3c_home_sector_22d.json';
import jsonData65 from './Data_file/aes_17189.json';
import jsonData66 from './Data_file/akriti_printers.json';
import jsonData67 from './Data_file/assotech_pvt.json';
import jsonData68 from './Data_file/smartcity_bbsr.json';
import jsonData69 from './Data_file/ornesh_medicare.json';
import { ValProvider } from './ValContext';

const searchPath = window.location.pathname;

const root = ReactDOM.createRoot(document.getElementById('root'));
var val;

if(searchPath === '/aes_11878'){
  val = jsonData0
}
else if (searchPath === '/aes_11879') {
  val = jsonData1
}
else if (searchPath === '/aes_11880') {
  val = jsonData2
}
else if (searchPath === '/aes_11881') {
  val = jsonData3
}
else if (searchPath === '/airth') {
  val = jsonData4
}
else if (searchPath === '/ayodhya') {
  val = jsonData5
}
else if (searchPath === '/cars24') {
  val = jsonData6
}
else if (searchPath === '/lti_mindtree') {
  val = jsonData7
}
else if (searchPath === '/tataariana' || searchPath === '/tata') {
  val = jsonData8
}
else if (searchPath === '/ubreathe') {
  val = jsonData9
}
else if(searchPath === '/ultrapure'){
  val = jsonData10
}
else if(searchPath === '/ultrapure1'){
  val = jsonData11
}
else if(searchPath === '/'){
  val = jsonData12
}
else if(searchPath === '/demo_omniion'){
  val = jsonData13
}
else if(searchPath === '/demo'){
  val = jsonData14
}
else if(searchPath === '/demo_9RN64GPE'){
  val = jsonData15
}
else if(searchPath === '/driiv'){
  val = jsonData16
}
else if(searchPath === '/driiv1'){
  val = jsonData17
}
else if(searchPath === '/ubreathe_new'){
  val = jsonData18
}
else if(searchPath === '/aes_13277'){
  val = jsonData19
}
else if(searchPath === '/demo1'){
  val = jsonData20
}
else if(searchPath === '/ubreathe1'){
  val = jsonData21
}
else if(searchPath === '/aes_11875'){
  val = jsonData22
}
else if(searchPath === '/ucan_centurion'){
  val = jsonData23
}
else if(searchPath === '/tdav_LBAZHT0G'){
  val = jsonData24
}
else if(searchPath === '/tdav_ZJRXKBYL'){
  val = jsonData25
}
else if(searchPath === '/tdav_R90J081M'){
  val = jsonData26
}
else if(searchPath === '/tdav_9RWJOVC5'){
  val = jsonData27
}
else if(searchPath === '/tdav_4X8I08SG'){
  val = jsonData28
}
else if(searchPath === '/tdav_Y060A0NP'){
  val = jsonData29
}
else if(searchPath === '/tdav_8IM301X6'){
  val = jsonData30
}
else if(searchPath === '/tdav_GEXFPYY4'){
  val = jsonData31
}
else if(searchPath === '/tdav_QPISM9E8'){
  val = jsonData32
}
else if(searchPath === '/demo_testing'){
  val = jsonData33
}
else if(searchPath === '/aes_14524'){
  val = jsonData34
}
else if(searchPath === '/shoba_housing'){
  val = jsonData35
}
else if(searchPath === '/aes_four_devices'){
  val = jsonData36
}
else if(searchPath === '/ace_tech_129'){
  val = jsonData37
}
else if(searchPath === '/ace_tech_150'){
  val = jsonData38
}
else if(searchPath === '/ace_tech_153'){
  val = jsonData39
}
else if(searchPath === '/ace_tech'){
  val = jsonData40
}
else if(searchPath === '/ace_tech_la_buildtech'){
  val = jsonData41
}
else if(searchPath === '/ace_tech_star_craft'){
  val = jsonData42
}
else if(searchPath === '/ubreathe_dc_mansion'){
  val = jsonData43
}
else if(searchPath === '/oxy_infra'){
  val = jsonData44
}
else if(searchPath === '/vishwa_marketing'){
  val = jsonData45
}
else if(searchPath === '/aes_afnkro80'){
  val = jsonData46
}
else if(searchPath === '/iclei_coimbatore'){
  val = jsonData47
}
else if(searchPath === '/ace_tech_sector_22D'){
  val = jsonData48
}
else if(searchPath === '/charms_india'){
  val = jsonData49
}
else if(searchPath === '/rudra_constructions'){
  val = jsonData50
}
else if(searchPath === '/okhae_electronics'){
  val = jsonData51
}
else if(searchPath === '/countryside_properties'){
  val = jsonData52
}
else if(searchPath === '/aes_16750'){
  val = jsonData53
}
else if(searchPath === '/bikaram_industries'){
  val = jsonData54
}
else if(searchPath === '/fit_bit'){
  val = jsonData55
}
else if(searchPath === '/aes_16968'){
  val = jsonData56
}
else if(searchPath === '/aes_16969'){
  val = jsonData57
}
else if(searchPath === '/emaar_india_ibc61'){
  val = jsonData58
}
else if(searchPath === '/ultrapure_soma_roadis'){
  val = jsonData59
}
else if(searchPath === '/rpdpl_17015'){
  val = jsonData60
}
else if(searchPath === '/rpdpl_17016'){
  val = jsonData61
}
else if(searchPath === '/rs_growplast'){
  val = jsonData62
}
else if(searchPath === '/albin_industries'){
  val = jsonData63
}
else if(searchPath === '/ace_tech_3c_home_sector_22d'){
  val = jsonData64
}
else if(searchPath === '/aes_17189'){
  val = jsonData65
}
else if(searchPath === '/akriti_printers'){
  val = jsonData66
}
else if(searchPath === '/assotech_pvt'){
  val = jsonData67
}
else if(searchPath === '/bbsrsmartcity'){
  val = jsonData68
}
else if(searchPath === '/ornesh_medicare'){
  val = jsonData69
}
else{
  val = jsonData12
}

root.render(

  // For development
  // <App reqData={val} />


  // For production
  // <React.StrictMode>
  //   <ValProvider reqData={val}>
  //     <App />
  //   </ValProvider>
  // </React.StrictMode>
  <React.StrictMode>
    <App reqData={val}/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
