import React, { useState, useEffect } from "react";

function Footer({ jsonData, data }) {

    const footer = jsonData.footer_content;
    const [pagedData, setPagedData] = useState([]);   // Stores the data for the current page
    const [index, setIndex] = useState(0);             // Stores the current page index
    let content = "";
    
    useEffect(() => {
        if (data && data.length > 0) {
            setPagedData(data[index]); // Set data based on the current index
        }
    }, [data, index]); // Re-run when data or index changes
    
    useEffect(() => {
        // If data exists and has multiple pages, start the paging
        if (data && data.length > 0) {
            const intervalId = setInterval(() => {
                setIndex(prevIndex => {
                    // Loop back to 0 when reaching the last index
                    return (prevIndex + 1) % data.length;
                });
            }, 10000); // Change every 10 seconds
    
            // Cleanup interval on component unmount
            return () => clearInterval(intervalId);
        }
    }, [data]); // Run this effect when data changes


    if (pagedData && pagedData.length > 0) {
        const item = pagedData.find(item => item.status);
        if (item) {
            switch (item.status) {
                case 'Good':
                    content = footer[0];
                    break;
                case 'Satisfactory':
                    content = footer[1];
                    break;
                case 'Moderately Polluted':
                    content = footer[2];
                    break;
                case 'Poor':
                    content = footer[3];
                    break;
                case 'Very Poor':
                    content = footer[4];
                    break;
                case 'Severe':
                    content = footer[5];
                    break;
                default:
                    content = "";
                    break;
            }
        }
    }

    return (
        <div className='smart-footer'>{content}</div>
    );
}

export default Footer;
