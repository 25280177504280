import React, { useEffect, useState } from 'react';
import MajorBody from './MajorBody'
import MinorBody from './MinorBody'

function Body({data}) {

    const [pagedData, setPagedData] = useState([]);   // Stores the data for the current page
    const [index, setIndex] = useState(0);             // Stores the current page index

    useEffect(() => {
        if (data && data.length > 0) {
            setPagedData(data[index]); // Set data based on the current index
        }
    }, [data, index]); // Re-run when data or index changes

    useEffect(() => {
        // If data exists and has multiple pages, start the paging
        if (data && data.length > 0) {
            const intervalId = setInterval(() => {
                setIndex(prevIndex => {
                    // Loop back to 0 when reaching the last index
                    return (prevIndex + 1) % data.length;
                });
            }, 10000); // Change every 5 seconds

            // Cleanup interval on component unmount
            return () => clearInterval(intervalId);
        }
    }, [data]); // Run this effect when data changes

    return (
        <div className='smart-body'>
            <MajorBody data={pagedData} />
            <MinorBody data={pagedData} />
        </div>
    );
}

export default Body
